import { useContext, useState, useMemo } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
  OverflowMenu,
  OverflowMenuItem,
  Modal,
} from "carbon-components-react";

import { Add16, DocumentExport20 } from "@carbon/icons-react";

import dayjs from "dayjs";
import useSWR, { useSWRConfig } from "swr";
import { DateTimeFormat } from "../../utils/datetime";
import { UserContext, CentersContext, StatusContext } from "../../context";
import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";

const headers = [
  {
    key: "center",
    name: "館別",
  },
  {
    key: "date",
    name: "送餐日",
  },
  {
    key: "progress_status",
    name: "報表狀態",
  },
  {
    key: "created",
    name: "產生時間",
  },

  {
    key: "report_file",
    name: "報表",
  },
];

const createQueryString = ({ center, offset, limit }) => {
  const params = new URLSearchParams();
  params.append("expand", "center");
  if (center !== undefined) {
    const cid = center.id;
    if (cid !== 0) {
      params.append("center", cid);
    }
  }
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (limit !== undefined) {
    params.append("limit", limit);
  }
  return params.toString();
};

export const ExportFoodVendorHistory = () => {
  const { list: centerList } = useContext(CentersContext);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultPageSize);
  const offset = (page - 1) * limit;

  const [center, setCenter] = useState();

  const queryString = useMemo(
    () => createQueryString({ center, offset, limit }),
    [center, offset, limit]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);

  const {
    token: { access },
  } = useContext(UserContext);
  let url = `${URL.reportOfExportFoodVendor}?${realyQuery}`;
  const { data } = useSWR(access && [url], F.withToken);

  const isLoading = !data;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/foodvendor" isCurrentPage>
          膳食統計
        </BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          清單
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>膳食統計</Tile>

      <Grid>
        <Column md={2}>
          <Dropdown
            id="center-selector"
            titleText="館別"
            label="全部"
            items={A.insert(centerList, { id: 0, name: "全部" }, 0)}
            itemToString={(item) => item.name}
            selectedItem={center}
            onChange={(v) => {
              const centerId = v.selectedItem;
              setCenter(centerId);
              const queryString = createQueryString({
                centerId,
                offset,
                limit,
              });
              setRealyQuery(queryString);
            }}
          />
        </Column>
      </Grid>

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <ExportFoodVendorHistoryTable
          page={page}
          offset={offset}
          limit={limit}
          inputData={data}
          onPageChange={({ page, pageSize }) => {
            const limit = pageSize;
            const offset = (page - 1) * limit;
            // offset and limit will not be update until the next event loop iteration
            setPage(page);
            setLimit(limit);
            // so we update the real query by ourselves
            const queryString = createQueryString({
              center,
              offset,
              limit,
            });
            setRealyQuery(queryString);
          }}
        />
      )}
    </Content>
  );
};

function ExportFoodVendorHistoryTable({
  page,
  limit,
  inputData,
  onPageChange,
}) {
  const { mutate } = useSWRConfig();

  const { list: centerList } = useContext(CentersContext);
  const { foodVendor: foodVendorList } = useContext(StatusContext);

  const pageSize = limit;
  const total = (inputData && inputData.count) || 0;
  const data = (inputData && inputData.results) || [];

  const cleanData = data.map(({ center, query_values, ...other }) => {
    const { date } = query_values;
    return {
      ...other,
      center: center?.name ?? "",
      date: date ?? "",
    };
  });

  const pageProps = {
    disabled: !data,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  const [openModal, setOpenModal] = useState(false);
  const [modalStatus, setModalStatus] = useState("inactive");

  const [center, setCenter] = useState();
  const [foodVendor, setFoodVendor] = useState();
  const [date, setDate] = useState();

  const handleSubmit = async () => {
    if (!center || !date) {
      console.debug(`no center or no date`, center, date);
      return;
    }

    setModalStatus("active");
    var dateString = null;
    if (date !== undefined) {
      dateString = dayjs(date).format(DateTimeFormat.date);
    }
    const payload = {
      report_type_str: "daily",
      query_values: {
        date: dateString,
        center: center.id,
        foodVendor: foodVendor,
      },
    };
    await F.post(URL.reportOfExportFoodVendor, payload);
    mutate([URL.reportOfExportFoodVendor]);
    setOpenModal(false);
    setModalStatus("finished");
  };

  return (
    <>
      <Modal
        open={openModal}
        modalHeading="新增報表"
        primaryButtonText="新增"
        secondaryButtonText="取消"
        hasScrollingContent
        isFullWidth
        loadingStatus={modalStatus}
        onRequestClose={() => setOpenModal(false)}
        onSecondarySubmit={() => setOpenModal(false)}
        onRequestSubmit={handleSubmit}
      >
        <Dropdown
          id="drop-center"
          titleText="館別"
          label="選擇館別"
          items={centerList}
          itemToString={(item) => item.name}
          selectedItem={center}
          onChange={(v) => {
            setCenter(v.selectedItem);
          }}
        />
        <Dropdown
          id="drop-foodvendor"
          titleText="膳食廠商"
          items={foodVendorList}
          itemToString={(item) => item.name}
          selectedItem={foodVendor}
          onChange={(v) => {
            setFoodVendor(v.selectedItem);
          }}
          disabled
        />
        <DatePicker
          dateFormat="Y/m/d"
          datePickerType="single"
          onChange={(date) => setDate(date)}
        >
          <DatePickerInput placeholder="yyyy/mm/dd" labelText="報表日期" />
        </DatePicker>
      </Modal>
      <DataTable rows={cleanData} headers={headers}>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent>
                <Button renderIcon={Add16} onClick={() => setOpenModal(true)}>
                  新增報表
                </Button>
              </TableToolbarContent>
            </TableToolbar>

            <Table {...getTableProps()} isSortable>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                      isSortable={header.key !== "actions"}
                    >
                      {header.name}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const { header = "" } = cell && cell.info;
                      /* const id = cell.id.split(":")[0]; */
                      /* console.debug(`id`, id, cell.value); */
                      let content;
                      switch (header) {
                        case "created":
                        case "date":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.date
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "center":
                        case "progress_status":
                          content = cell.value;
                          break;
                        case "report_file":
                          content = (
                            <Button
                              kind="ghost"
                              renderIcon={DocumentExport20}
                              as="a"
                              href={cell.value}
                              disabled={
                                cell.value === null || cell.value === ""
                              }
                            />
                          );
                          break;
                        case "actions":
                          content = (
                            <OverflowMenu size="sm" flipped disabled>
                              <OverflowMenuItem itemText="下載檔案" href="" />
                            </OverflowMenu>
                          );
                          break;
                        default:
                          content = <span>?</span>;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Pagination {...pageProps} />
          </TableContainer>
        )}
      </DataTable>
    </>
  );
}
