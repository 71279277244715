import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  Grid,
  Row,
  TextInputSkeleton,
  Tile,
} from "carbon-components-react";
import { Save16, Undo16 } from "@carbon/icons-react";

import useSWR from "swr";

import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";
import * as S from "../../utils/schema";
import { SchemaEditor } from "../../components/Editor";
import { SchemaContext, UserContext } from "../../context";
import { useUserData } from "../../hooks";
import { FoodVendorRow } from "../../components/Viewer";

const EditorWrapper = styled.div`
  margin: 16px;
`;

const OrderRowWrapper = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 24px;
`;

const excludeFields = [
  "id",
  "created",
  "modified",
  "employee_id",
  "housing",
  "activate_date",
  "deactivate_date",
  "status",
  "order_beverage",
  "order_beverage_list",
];

export const BeverageCreateOrEdit = () => {
  const { hid, bhid } = useParams();

  const {
    token: { access },
  } = useContext(UserContext);

  const navigate = useNavigate();
  const user = useUserData();

  const { beverageHistory: schema, orderBeverage: schemaOfOrderBeverage } =
    useContext(SchemaContext) || {};

  const isEdit = !!bhid;

  const [puerperaDisplay, setPuerperaDisplay] = useState("產婦");
  const [currentFoodVendor, setCurrentFoodVendor] = useState();

  const housingURL = `${URL.housing}${hid}/?expand=puerperaNote.puerpera,bookRoom.current_brs.room.center,bookRoom.latest_brs.room.center,foodvendor`;
  const { data: housingData } = useSWR(access && [housingURL], F.withToken);

  useEffect(() => {
    if (!housingData || housingData == null) {
      return;
    }

    const puerpera = housingData?.puerperaNote?.puerpera ?? {};
    const cc = housingData?.bookRoom.current_brs.room.center ?? {};
    const lc = housingData?.bookRoom.latest_brs.room.center ?? {};
    var center = undefined;
    if (cc.name !== undefined) {
      center = cc;
    } else if (lc.name !== undefined) {
      center = lc;
    }
    const center_name = center !== undefined ? `(${center.name})` : "";

    setPuerperaDisplay(`${center_name} ${puerpera.name}`);
  }, [housingData]);

  const changeFVHURL = `${URL.changeFoodVendorHistory}?expand=foodvendor.center&limit=1`;
  const { data: rawFoodVendorData } = useSWR(
    access && [changeFVHURL],
    F.withToken
  );

  const historyURL = `${URL.beverageHistory}${bhid}?expand=order_beverage_list`;
  const { data: historyData } = useSWR(
    isEdit ? access && [historyURL] : null,
    F.withToken
  );

  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (!rawFoodVendorData || rawFoodVendorData == null) {
      return;
    }
    // 找到 start_date 是空的，為第一個
    const findFirst = (fv) => fv.start_date === null;
    var firstFV = rawFoodVendorData.find(findFirst);
    if (firstFV === undefined) {
      return;
    }
    setCurrentFoodVendor(firstFV.foodvendor);
  }, [rawFoodVendorData]);

  const [orderBeverageList, setOrderBeverageList] = useState([]);
  const [displayOfOrderBeverage, setDisplayOfOrderBeverage] = useState({});

  const beverageURL = `${URL.beverage}?expand=meal_type&status=1&foodvendor=${
    currentFoodVendor?.id ?? 0
  }`;
  const { data: beverageData } = useSWR(
    access && !!currentFoodVendor ? [beverageURL] : null,
    F.withToken
  );

  const initialBeverageHistory = useMemo(
    () => S.createInitialData(schema),
    [schema]
  );
  const [beverageHistory, setBeverageHistory] = useState(
    initialBeverageHistory
  );

  useEffect(() => {
    if (!beverageData) return;

    // use meal_type.name to display
    let beverageWithMealType = {};
    beverageData.forEach((ob) => {
      const mealName = ob.meal_type?.name ?? "";
      if (!!mealName) {
        const preList = !!beverageWithMealType[mealName]
          ? beverageWithMealType[mealName]
          : [];
        beverageWithMealType[mealName] = A.append(preList, ob);
      }
    });
    setDisplayOfOrderBeverage(beverageWithMealType);
    if (isEdit) return;
    setOrderBeverageList(
      beverageData.map((ob) => {
        return {
          ...S.createInitialData(schemaOfOrderBeverage),
          beverage: ob.id,
        };
      })
    );
  }, [
    beverageData,
    setDisplayOfOrderBeverage,
    isEdit,
    setOrderBeverageList,
    schemaOfOrderBeverage,
  ]);

  useEffect(() => {
    if (!historyData) return;
    const { id, order_beverage_list, start_date, comment } = historyData;
    setBeverageHistory({ id, start_date, comment });

    if (!isEdit) return;
    setOrderBeverageList(order_beverage_list);
  }, [historyData, setBeverageHistory, isEdit, setOrderBeverageList]);

  // useEffect(() => {
  //   if (!user) return;
  //   if (!orderBeverageList) return;
  //   setBeverageHistory((x) => {
  //     return {
  //       ...x,
  //       order_beverage: orderBeverageList,
  //     }
  //   });
  // }, [orderBeverageList, setBeverageHistory, user, hid])

  const isHistoryReady = S.createSimpleValidator(schema, excludeFields);
  const isReady = useMemo(
    () => isHistoryReady(beverageHistory),
    [isHistoryReady, beverageHistory]
  );
  // console.debug(`isReady`, isReady, beverageHistory);

  const handleCancel = useCallback(async () => {
    navigate(`/housing/${hid}/beverage`);
  }, [navigate, hid]);

  const handleSubmit = useCallback(async () => {
    if (!isReady) return;
    if (isSending) return;

    const { id, ...o } = beverageHistory;
    const payload = { ...o };

    setIsSending(true);
    if (isEdit) {
      await F.put(`${URL.beverageHistory}${id}/`, payload);
    } else {
      await F.post(`${URL.beverageHistory}`, payload);
    }
    setIsSending(false);
    navigate(`/housing/${hid}/beverage`);
  }, [
    navigate,
    hid,
    isReady,
    isEdit,
    isSending,
    setIsSending,
    beverageHistory,
  ]);

  const handleUpdate = useCallback(
    (bid) => (s) => {
      // console.debug(`update bid`, bid, s);
      setOrderBeverageList((ss) => {
        const idx = ss.findIndex((x) => x.beverage === bid);
        return A.replace(ss, { ...ss[idx], ...s }, idx);
      });
    },
    [setOrderBeverageList]
  );

  const isEditTitle = isEdit ? "編輯" : "新增";
  const storeButtonTitle = isEdit ? "儲存" : "新增";

  return (
    <Content className="foodVendorReason-createOrEdit">
      <Breadcrumb>
        <BreadcrumbItem href="">住房管理</BreadcrumbItem>
        <BreadcrumbItem href={`/housing/${hid}`}>
          {puerperaDisplay}
        </BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          變更飲品設定
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>{isEditTitle}更換飲品</Tile>

      <FoodVendorRow
        puerpera={housingData?.puerperaNote?.puerpera ?? {}}
        foodVendor={currentFoodVendor}
      />

      <Grid>
        {/* TODO: change to use ContainedList */}
        {displayOfOrderBeverage ? (
          Object.keys(displayOfOrderBeverage).map((key) => {
            const items = displayOfOrderBeverage[key].map((d) => {
              const bid =
                d.id !== undefined ? d.id : `${d.foodvendor}_${d.name}`;
              const obData =
                orderBeverageList.find((x) => x.beverage === d.id) || {};

              return (
                <OrderRowWrapper>
                  <OrderRow
                    key={bid}
                    id={bid}
                    data={obData}
                    onChange={handleUpdate(bid)}
                  />
                </OrderRowWrapper>
              );
            });

            return (
              <Column>
                <Tile>{key}</Tile>
                {items}
              </Column>
            );
          })
        ) : (
          <TextInputSkeleton />
        )}

        <Column>
          <EditorWrapper>
            <SchemaEditor
              fields={schema.fields}
              excludes={excludeFields}
              data={beverageHistory}
              onChange={(x) => {
                if (!user) return;
                setBeverageHistory({
                  ...x,
                  housing: hid,
                  employee_id: user.employee_id,
                  order_beverage: orderBeverageList,
                });
              }}
            />
          </EditorWrapper>
        </Column>

        <Column>
          <Grid>
            <Row>
              <Column sm={{ span: 1, offset: 1 }}>
                <Button
                  kind="secondary"
                  size="small"
                  renderIcon={Undo16}
                  onClick={handleCancel}
                >
                  取消
                </Button>
              </Column>
              <Column>
                <Button
                  disabled={!isReady}
                  kind="primary"
                  size="small"
                  renderIcon={Save16}
                  onClick={handleSubmit}
                >
                  {storeButtonTitle}
                </Button>
              </Column>
            </Row>
          </Grid>
        </Column>
      </Grid>
    </Content>
  );
};

const OrderRow = ({ id, data, onChange }) => {
  const { orderBeverage: schema } = useContext(SchemaContext) || {};
  const user = useUserData();

  const handleChangeEvent = useCallback(
    (s) => {
      if (typeof onChange !== "function") return;
      onChange(s);
    },
    [onChange]
  );

  return (
    <>
      <Column>品項：{data.name}</Column>
      <Column sm={2}>
        <EditorWrapper>
          <SchemaEditor
            id={`editor_${id}`}
            fields={schema.fields}
            orderFields={["id", "beverage", "is_order"]}
            excludes={["id", "beverage_history", "beverage"]}
            data={data}
            onChange={(x) => {
              if (!user) return;
              handleChangeEvent(x);
            }}
          />
        </EditorWrapper>
      </Column>
    </>
  );
};
